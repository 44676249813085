<template>
<div class='editHeaderLogo'>
	<div class='logo' v-if='url'>
		<LazyImage :src='url' :contain='true' />
		<CloseButton @click='$emit("unsetLogo")' />	
	</div>
	<UploadLogo v-else v-on='$listeners' :getUploadUrl='getUploadUrl' />
</div>
</template>

<script>
import CloseButton from '@/components/common/buttons/Close'
import LazyImage from '@/components/common/LazyImage'
import UploadLogo from './UploadLogo'

export default {
	name: 'EditHeaderLogo',
	components: { CloseButton, LazyImage, UploadLogo },
	props: ['url', 'getUploadUrl'],
	computed: {},
	methods: {}
}
</script>

<style lang='scss'>
.editHeaderLogo {
	position: relative;

	.logo {
		@include absTopLeft;
		@include fill;
		display: flex;
		align-items: center;		
		.closeButton { flex: 0; }
	}

}
</style>
