<template>
<div class='settingsBilling'><div>
	<header>{{$t('views.settings.billing.title')}}</header>
	
	<div class='controlBar'>

		<a v-if='$store.state.profile.user.billing' class='iconTextButton neutral' :href='billingUrl' target='_blank'>
			<svg class='smallIcon'>
				<use xlink:href='#billing' />
			</svg>
			<span class='label'>{{$t('views.settings.billing.btnBilling')}}</span>
		</a>

		<div v-if='!$store.state.profile.user.orgId && $store.state.profile.user.billing' class='iconTextButton neutral' @click='showCloseModal'>
			<svg class='smallIcon'>
				<use xlink:href='#closeAccount' />
			</svg>
			<span class='label'>{{$t('views.settings.billing.btnClose')}}</span>
		</div>

	</div>

	<transition name='fade'><Loading v-show='loading' /></transition>
</div></div>
</template>

<script>
import { apiPath } from '@/configuration/settings'
import { smallModalMixin } from '@/components/common/mixins/modal'
import Loading from '@/components/common/Loading'
import CloseModal from './CloseModal'

export default {
	name: 'SettingsBilling',
	mixins: [smallModalMixin],
	components: { Loading },
	data: () => ({ billingUrl: apiPath + '/billing' }),
	computed: {
		loading() { return this.$store.state.profile.apiPending }
	},
	methods: {
		showCloseModal() {
			this.showSmallModal(CloseModal)
		}
	}
}
</script>

<style lang='scss'>
.settingsBilling {
	padding-left: $size-gutter * 4;
	background: $color-white;

	> div {
		position: relative;
		max-width: 500px;
	}
}
</style>
