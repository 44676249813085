<template>
<SmallModal class='closeReasonModal' @close='$emit("close")'>
	<template v-slot:title>{{$t('views.closeAccountReason.title')}}</template>
	<template v-slot:message>
		<p>{{$t('views.closeAccountReason.p1')}}</p>
		<div class='reasons'>
			<div @click='reason = "TOO_EXPENSIVE"' :class='{ selected: reason === "TOO_EXPENSIVE" }'>
				<CheckButton :checked='reason === "TOO_EXPENSIVE"' />
				<div class='reason'>{{$t('views.closeAccountReason.reason1')}}</div>
			</div>
			<div @click='reason = "MISSING_FEATURES"' :class='{ selected: reason === "MISSING_FEATURES" }'>
				<CheckButton :checked='reason === "MISSING_FEATURES"' />
				<div class='reason'>{{$t('views.closeAccountReason.reason2')}}</div>
			</div>
			<div @click='reason = "ANOTHER_SOFTWARE"' :class='{ selected: reason === "ANOTHER_SOFTWARE" }'>
				<CheckButton :checked='reason === "ANOTHER_SOFTWARE"' />
				<div class='reason'>{{$t('views.closeAccountReason.reason3')}}</div>
			</div>
			<div @click='reason = "DONT_USE"' :class='{ selected: reason === "DONT_USE" }'>
				<CheckButton :checked='reason === "DONT_USE"' />
				<div class='reason'>{{$t('views.closeAccountReason.reason4')}}</div>
			</div>
			<div @click='reason = "LEFT_PRACTICE"' :class='{ selected: reason === "LEFT_PRACTICE" }'>
				<CheckButton :checked='reason === "LEFT_PRACTICE"' />
				<div class='reason'>{{$t('views.closeAccountReason.reason5')}}</div>
			</div>
			<div @click='reason = "OTHER"' :class='{ selected: reason === "OTHER" }'>
				<CheckButton :checked='reason === "OTHER"' />
				<div class='other'>
					<TextInput 
						ref='other'
						v-model='other' 
						:placeholder='$t("views.closeAccountReason.phOther")' 
					/>
				</div>
			</div>
		</div>
		<transition name='fade'><Loading v-show='loading' /></transition>
	</template>
	<template v-slot:buttons>
		<EnabledButton 
			@click='close' 
			:svg='"#closeAccount"' 
			:label='$t("views.closeAccount.title")' 
			:disabled='!(reason && (reason !== "OTHER" || other))'
			class='primary' 
		/>
	</template>
</SmallModal>
</template>

<script>
import { profileApi } from '@/services/api/modules/profile'
import { smallModalMixin } from '@/components/common/mixins/modal'
import Loading from '@/components/common/Loading'
import SmallModal from '@/components/common/modals/SmallModal'
import EnabledButton from '@/components/common/buttons/Enabled'
import CheckButton from '@/components/common/buttons/Check'
import TextInput from '@/components/common/TextInput'
import AlertModal from '@/components/common/modals/Alert'

export default {
	mixins: [smallModalMixin],
	components: { Loading, SmallModal, EnabledButton, CheckButton, TextInput },
	data: () => ({ loading: false, other: '', reason: '' }),
	methods: {
		async close() {
			const reason = this.reason === 'OTHER' ? this.other : this.reason
			this.reason = ''
			this.loading = true
			const [err] = await profileApi.touch('close', { reason })
			this.loading = false
			this.$emit('close')
			if (!err) {
				this.showSmallModal(AlertModal, {
					title: this.$t('views.closeAccountReason.successTitle'),
					message: this.$t('views.closeAccountReason.successMsg'),
					fn: () => this.$store.dispatch('auth/logout')
				})
			}
		}
	},
	watch: {
		reason(value) {
			if (value === 'OTHER') this.$refs.other.focus()
		}
	}

}
</script>

<style lang='scss'>
.closeReasonModal {
	.reasons {

		margin: $size-gutter 0;

		> div {
			display: flex;
			align-items: center;
			height: $size-control-height;

			&:not(:last-child) { border-bottom: 1px solid $color-neutral-accent; }

			&.selected { background: $color-focus; }
			
			.checkButton {
				flex: 0;
				padding-left: $size-gutter * 2;
				pointer-events: none;
			}

			.reason {
				flex: 1;
				font-size: $size-font-standard;
				padding-left: $size-gutter * 2;
				position: relative;
				top: -1px;
			}

			.other { 
				flex: 1;
			}
		}
	}
}
</style>