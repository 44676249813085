<template>
<div class='settings'>
	<Nav />
	<router-view />
</div>
</template>

<script>
import { simpleState } from '@/store/helpers'
import Nav from './Nav'

export default {
	name: 'Settings',
	components: { Nav },
	watch: {
		$route: (current) => simpleState.settingsPath = current.path
	},
	mounted() {
		this.$store.dispatch('profile/fetch')
	}
}
</script>

<style lang='scss'>
@import './scss/settings.scss';
</style>
