<template>
<div class='headerFooterList'>
	<HeaderFooterRow 
		v-for='(headerFooter, index) in $store.getters["headerFooters/list"]' 
		:key='index'
		:headerFooter='headerFooter' 
	/>
	<div class='iconTextButton neutral' @click='create' ref='createButton'>
		<svg class='smallIcon'>
			<use xlink:href='#plus' />
		</svg>
		<span class='label'>{{ $t('elements.buttons.header') }}</span>
	</div>
	<transition name='fade'><Loading v-show='$store.state.headerFooters.apiPending' /></transition>
</div>
</template>

<script>
import Loading from '@/components/common/Loading'
import HeaderFooterRow from './Row'

export default {
	name: 'HeaderFooterList',
	components: { HeaderFooterRow, Loading },
	methods: {
		create() {
			this.$store.dispatch('headerFooters/create')
				.then(() => this.$refs.createButton.scrollIntoView())
		}
	}
}
</script>

<style lang='scss'>
.headerFooterList {
	position: relative;
	border: 1px solid $color-neutral-shadow;
	background: $color-neutral-shadow;
	margin-bottom: $size-gutter * 4;
}
</style>
