<template>
<div class='settingsNav'>
	<router-link tag='div' :to='{ name: "clinicianSettingsPersonal" }'><div>{{$t('elements.buttons.personalInfo')}}</div></router-link>
	<router-link v-if='$store.state.profile.user.manager || ($store.state.profile.user.sole && $store.state.profile.user.orgId)' tag='div' :to='{ name: "clinicianSettingsClinic" }'><div>Company Info</div></router-link>
	<router-link tag='div' :to='{ name: "clinicianSettingsPassword" }'><div>{{$t('elements.buttons.password')}}</div></router-link>
	<router-link tag='div' :to='{ name: "clinicianSettingsSecurity" }'><div>{{$t('elements.buttons.security')}}</div></router-link>
	<router-link v-if='$store.state.profile.user.billing && !$store.state.profile.user.manager' tag='div' :to='{ name: "clinicianSettingsBilling" }'><div>{{$t('elements.buttons.billing')}}</div></router-link>
	<router-link tag='div' :to='{ name: "clinicianSettingsIntegrations" }'><div>{{$t('elements.buttons.integrations')}}</div></router-link>
	<router-link tag='div' :to='{ name: "clinicianSettingsHeaders" }'><div>{{$t('elements.buttons.headers')}}</div></router-link>
	<router-link tag='div' :to='{ name: "clinicianSettingsEmail" }'><div>{{$t('elements.buttons.defaultClientEmail')}}</div></router-link>
	<div @click='showLanguageModal'><div>{{ $t('elements.nav.language') }} ({{ $store.state.profile.user.lang }})</div></div>
</div>
</template>

<script>
import { smallModalMixin } from '@/components/common/mixins/modal'
import Language from '@/components/common/modals/Language'

export default {
	name: 'SettingsNav',
	mixins: [smallModalMixin],
	methods: {
		showLanguageModal() {
			this.showSmallModal(Language)
		}
	}
}
</script>

<style lang='scss'>
@import './scss/nav.scss';
</style>
