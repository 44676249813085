<template>
<SmallModal class='languageModal' @close='$emit("close")' @doDefault='doFn'>
	<template v-slot:title>{{$t("views.language.title")}}</template>
	<template v-slot:message>
		<div class='checkList'>
			<div @click='lang="en"' :class='{ selected: lang==="en" }'>
				<CheckButton :checked='lang==="en"' />
				{{$t('elements.langs.en')}}
			</div>
			<div @click='lang="fr"' :class='{ selected: lang==="fr" }'>
				<CheckButton :checked='lang==="fr"' />
				{{$t('elements.langs.fr')}}
			</div>
		</div>
		<transition name='fade'><Loading v-show='working' /></transition>
	</template>
	<template v-slot:buttons>
		<div class='iconTextButton primary' @click='doFn'>
			<svg class='smallIcon'>
				<use xlink:href='#check' />
			</svg>
			<span class='label'>{{$t("elements.langs."+ lang)}}</span>
		</div>
	</template>
</SmallModal>
</template>

<script>
import Loading from '@/components/common/Loading'
import CheckButton from '@/components/common/buttons/Check'
import SmallModal from './SmallModal'

export default {
	components: { SmallModal, CheckButton, Loading },
	data: function() {
		return {
			lang: this.$store.state.profile.user.lang,
			fnDone: false,
			working: false
		}
	},
	computed: {
	},
	methods: {
		doFn() {
			if (this.fnDone) return
			this.fnDone = true
			this.working = true
			this.$store.dispatch('profile/setLang', this.lang)
				.finally(() => this.$emit('close'))
		}
	}
}
</script>

<style lang='scss'>
.languageModal {
	.checkButton {
		pointer-events: none;
	}
}
</style>