<template>
<SmallModal class='removeIntegrationModal' @close='$emit("close")'>
	<template v-slot:title>{{$t('views.removeIntegration.title')}}</template>
	<template v-slot:message>
		<p>{{$t('views.removeIntegration.p1')}}</p>
		<div class='inputWrapper'>
			<TextInput 
				v-model='textInput' 
				:placeholder='$t("views.removeIntegration.phRemove")' 
			/>
		</div>
	</template>
	<template v-slot:buttons>
		<EnabledButton 
			@click='removeIntegration' 
			:disabled='textInput !== "REMOVE"' 
			:svg='"#closeAccount"' 
			:label='$t("views.removeIntegration.title")' 
			class='primary' 
		/>
	</template>
</SmallModal>
</template>

<script>
import SmallModal from '@/components/common/modals/SmallModal'
import TextInput from '@/components/common/TextInput'
import EnabledButton from '@/components/common/buttons/Enabled'

export default {
	props: ['fn'],
	components: { SmallModal, TextInput, EnabledButton },
	data: () => ({ textInput: '' }),
	methods: {
		removeIntegration() {
			this.fn()
			this.$emit('close')
		}
	}
}
</script>

<style lang='scss'>
.removeIntegrationModal {
	.inputWrapper {
		padding: $size-gutter * 2 0;
		.textInput {
			border: 1px solid $color-neutral-accent;
		}
	}
}
</style>