<template>
<div class='settingsEmail'>
	<Scroll><div class='contentWrapper formContent'>
		<header>{{$t('views.settings.email.title')}}</header>
		<p>{{$t('views.settings.email.p1')}}</p>
		<div class='field'>
			<div class='label'>
				{{$t("elements.labels.cc")}}:
				<svg class='detail' @click='detailCC'>
					<use xlink:href='#detail' />
				</svg>
			</div>
			<EmailsInput 
				ref='cc'
				v-model='cc' 
				:placeholder='$t("views.emailSet.phEmailCC")' 
				:maxlength='500'
				:maxtags='5'
			/>
		</div>
		<div class='field'>
			<div class='label'>{{$t("elements.labels.message")}}:</div>
			<RichText 
				ref='body'
				v-model='body' 
				:placeholder='$t("views.emailSet.phEmailBody")' 
				:maxlength='1000'
			/>
		</div>
		<div class='setLink'>{{$t("views.emailSet.link")}}</div>
		<div class='field'>
			<div class='label'>{{$t("elements.labels.signature")}}:</div>
			<RichText 
				ref='signature'
				v-model='signature' 
				:placeholder='$t("views.emailSet.phEmailSignature")' 
				:maxlength='500'
			/>
		</div>
		<div class='controlBar'>
			<SaveButton class='saveButton' @click='save' />
			<div class='iconTextButton neutral' @click='reset'>
				<svg class='smallIcon'>
					<use xlink:href='#undo' />
				</svg>
				<span class='label'>{{$t("views.settings.email.btnReset")}}</span>
			</div>
		</div>
		<transition name='fade'><Loading v-show='working' /></transition>
	</div></Scroll>
</div>
</template>

<script>
import { smallModalMixin } from '@/components/common/mixins/modal'
import Scroll from '@/components/common/Scroll'
import AlertModal from '@/components/common/modals/Alert'
import EmailsInput from '@/components/common/EmailsInput'
import RichText from '@/components/common/RichText'
import SaveButton from '@/components/common/buttons/Save'
import Loading from '@/components/common/Loading'

export default {
	name: 'SettingsEmail',
	mixins: [smallModalMixin],
	components: { Scroll, EmailsInput, RichText, SaveButton, Loading },
	data() {
		return {
			cc: this.$store.state.profile.user.clientEmailCC,
			body: this.$store.state.profile.user.clientEmailBody,
			signature: this.$store.state.profile.user.clientEmailSig,
			working: false
		}
	},
	computed: {
		lang() { return this.$store.state.profile.user.lang }
	},
	methods: {
		async save() {
			this.working = true
			const [err, result] = await this.$store.dispatch('profile/save', {
				clientEmailCC: this.cc,
				clientEmailBody: this.body,
				clientEmailSig: this.signature
			})
			this.working = false
			if (!err) this.onSaved(result)
		},
		async reset() {
			this.working = true
			const [err, result] = await this.$store.dispatch('profile/save', {
				clientEmailCC: [],
				clientEmailBody: null,
				clientEmailSig: null
			})
			this.working = false
			if (!err) {
				this.onSaved(result)
				this.$refs.cc.reset()
			}

		},
		onSaved(result) {
			this.$store.dispatch('flash/showAction', 'saved')
			this.cc = result.clientEmailCC
			this.body = result.clientEmailBody
			this.signature = result.clientEmailSig
			this.$refs.body.editor.setContent(this.body)
			this.$refs.signature.editor.setContent(this.signature)
		},
		detailCC() {
			this.showSmallModal(AlertModal, {
				title: this.$t('views.detailCCReceipt.title'),
				message: this.$t('views.detailCCReceipt.p1')
			})
		}
	},
	watch: {
		lang() {
			this.body = this.$store.state.profile.user.clientEmailBody
			this.signature = this.$store.state.profile.user.clientEmailSig
			this.$refs.body.editor.setContent(this.body)
			this.$refs.signature.editor.setContent(this.signature)
		}
	},
}
</script>

<style lang='scss'>
.settingsEmail {
	position: relative;
	background: $color-white;

	.contentWrapper {
		margin: 0 $size-gutter * 4;
		max-width: 500px;
	}

	.setLink {
		height: $size-control-height;
		margin-left: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: $size-font-standard;
		background: $color-neutral;
	}

	.controlBar {
		margin-left: 100px;
		margin-top: $size-gutter * 5;
		width: 400px;
	}
}
</style>
