<template>
<div class='settingsIntegrations formContent'><div>
	<header>{{$t('views.settings.integrations.title')}}</header>
	<template v-if='emrTitle'>
		<template v-if='emrEnabled'>
			<p>{{$t('views.settings.integrations.p1')}} <b>{{integratedWith}}</b></p> 
			<div class='logos'>
				<div><AnimatedLogo :text='false' /></div>
				<div><svg class='smallIcon'><use xlink:href='#angleRight' /></svg></div>
				<div><img :src='`/img/integrations/${emrTitle}.png`' /></div>
			</div>
		</template>
		<p class='emrErr' v-else v-html='$t("views.integrations."+emrTitle+".err")' />
		<div v-if='!emrEnabled' class='iconTextButton neutral restartIntegrationButton' @click='restartIntegration'>
			<svg class='smallIcon'>
				<use xlink:href='#check' />
			</svg>
			<span class='label'>{{$t('views.settings.integrations.btnRestart')}}</span>
		</div>		
		<header>{{$t('views.settings.integrations.update')}}</header>
		<component :is='emrTitle' ref='integration' />
		<SaveButton class='saveButton' @click='save' />
		<header>{{$t('views.settings.integrations.remove')}}</header>
		<div class='iconTextButton neutral' @click='showRemove'>
			<span class='label'>{{$t('views.settings.integrations.btnRemove')}}</span>
		</div>
	</template>
	<template v-else>
		<SearchSelectInput 
			class='integrationsSelect'
			:options='integrationsSelect' 
			:placeholder='$t("views.settings.integrations.phSelectIntegration")'
			v-model='selectedIntegration'
		/>
		<template v-if='selectedIntegration'>
			<component :is='selectedIntegration.type' ref='integration' />
			<SaveButton class='saveButton' @click='save' />
		</template>
	</template>
	<transition name='fade'><Loading v-show='working' /></transition>
</div></div>
</template>

<script>
import { smallModalMixin } from '@/components/common/mixins/modal'
import SearchSelectInput from '@/components/common/SearchSelectInput'
import AnimatedLogo from '@/components/common/AnimatedLogo'
import TextInput from '@/components/common/TextInput'
import SaveButton from '@/components/common/buttons/Save'
import Loading from '@/components/common/Loading'
import AlertModal from '@/components/common/modals/Alert'
import RemoveIntegrationModal from './RemoveIntegrationModal'

export default {
	name: 'SettingsIntegrations',
	mixins: [smallModalMixin],
	components: { 
		AnimatedLogo, SearchSelectInput, TextInput, SaveButton, Loading,
		cliniko: () => import('./integrations/Cliniko'),
		coreplus: () => import('./integrations/Coreplus'),
		juvonno: () => import('./integrations/Juvonno'),
		nookal: () => import('./integrations/Nookal')
	},
	data() {
		return {
			integrations: {
				cliniko: 'Cliniko Practice Management Software',
				coreplus: 'Coreplus Digital Health Practice Management',
				juvonno: 'Juvonno Clinic Management Software',
				nookal: 'Nookal Practice Management Software'
			},
			integrationsSelect: [
				{ label: 'Cliniko', type: 'cliniko' },
				{ label: 'Coreplus', type: 'coreplus' },
//				{ label: 'Juvonno', type: 'juvonno' },
				{ label: 'Nookal', type: 'nookal' }
			],
			selectedIntegration: null,
			working: false
		}
	},
	computed: {
		emrTitle() { return this.$store.state.profile.emr ? this.$store.state.profile.emr.title : '' }, 
		emrEnabled() { return this.$store.state.profile.emr ? this.$store.state.profile.emr.enabled : false }, 
		integratedWith() { return this.integrations[this.emrTitle] },
	},
	methods: {
		showRemove() {
			const fn = async () => {
				this.working = true
				await this.$store.dispatch('profile/removeIntegration')
				this.working = false
				this.$store.dispatch('flash/showAction', 'saved')
			}
			this.showSmallModal(RemoveIntegrationModal, { fn })
		},
		async save() {
			const type = this.emrTitle || this.selectedIntegration.type
			const payload = Object.assign(this.$refs.integration.get(), { type })
			this.working = true
			const [err, result] = await this.$store.dispatch('profile/integrate', payload)
			this.working = false
			this.$refs.integration.reset()
			if (err) {
				this.$refs.integration.showErr()	
			} else {
				this.$store.dispatch('flash/showAction', 'saved')
				if (result && typeof result === 'object' && result.apiKey) {
					const message = `
						${this.$t('views.integrations.reciprocalKey.p1')} <b>${this.selectedIntegration.label}.</b><br /><br />
						<input type='text' value='${result.apiKey}' style='width: 100%; height: 40px; border: 1px solid #ccc; padding: 0 14px;' /><br /><br /> 
						${this.$t('views.integrations.reciprocalKey.p2')}
					`
					this.showSmallModal(AlertModal, {
						title: this.$t('views.integrations.reciprocalKey.title'),
						message,
						sticky: true
					}, 700)					
				}
			}
		},
		async restartIntegration() {
			this.working = true
			await this.$store.dispatch("profile/restartIntegration")
			this.working = false
		}
	}
}
</script>

<style lang='scss'>
.settingsIntegrations {
	padding-left: $size-gutter * 4;
	background: $color-white;

	> div {
		position: relative;
		max-width: 500px;
	}

	.logos {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: $size-gutter * 5;
		> div { 
			position: relative; height: 100px; 
			display: flex;
			align-items: center;
			justify-content: center;
		}
		> div:first-child { flex-basis: 100px; }
		> div:last-child { 
			flex-basis: 250px;
			img { width: 100%; height: 100%; object-fit: contain; object-position: 0 50%; }
			margin-right: auto;
		}
		> div:nth-child(2) svg {
			width: 32px;
			height: 32px;
			margin: 0 $size-gutter * 2;
		}
	}

	.iconTextButton {
		border: 1px solid $color-neutral-shadow;
		margin-top: $size-gutter * 5;
	}

	.restartIntegrationButton {
		margin-top: 0;
		margin-bottom: $size-gutter * 5;
	}

	.integrationsSelect {
		border: 1px solid $color-neutral-shadow;	
		margin-bottom: $size-gutter * 2;	
	}

	.saveButton {
		margin-top: $size-gutter * 2;
		margin-bottom: $size-gutter * 5;
		border: 1px solid $color-neutral-shadow;
	}

	.emrErr { color: $color-alert; }

	.iconTextButton {
		width: 250px;		
	}
}
</style>
