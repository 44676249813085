<template>
<div class='editHeaderFooter'>
	<div class='headerText formContent'>
		<div class='field'>
			<div class='label'>{{$t("views.headerFooter.lblLine1")}}:</div>
			<TextInput 
				:value='headerLine1'
				@input='value => $emit("setHeaderLine1", value)'
				maxlength='50' 
				:placeholder='$t("views.headerFooter.lblLine1")' 
			/>
		</div>
		<div class='field'>
			<div class='label'>{{$t("views.headerFooter.lblLine2")}}:</div>
			<TextInput 
				:value='headerLine2'
				@input='value => $emit("setHeaderLine2", value)'
				maxlength='50' 
				:placeholder='$t("views.headerFooter.lblLine2")' 
			/>
		</div>
		<div class='field'>
			<div class='label'>{{$t("views.headerFooter.lblLine3")}}:</div>
			<TextInput 
				:value='headerLine3'
				@input='value => $emit("setHeaderLine3", value)'
				maxlength='50' 
				:placeholder='$t("views.headerFooter.lblLine3")' 
			/>
		</div>
		<div class='field'>
			<div class='label'>{{$t("views.headerFooter.lblFooter")}}:</div>
			<TextInput 
				:value='footer'
				@input='value => $emit("setFooter", value)'
				maxlength='30' 
				:placeholder='$t("views.headerFooter.lblFooter")' 
			/>
		</div>
	</div>
	<Logo 
		:url='logo' 
		:getUploadUrl='getUploadUrl'
		@uploadStart='uploading=true' 
		@uploadStop='uploading=false' 
		@uploadSuccess='value => $emit("setLogo", value)'
		@unsetLogo='$emit("unsetLogo")'
	/>
	<div class='controlBar'>
		<EnabledButton 
			@click='$emit("stopEdit")' 
			:disabled='uploading' 
			:svg='"#check"' 
			:label='$t("elements.buttons.ok")' 
			class='neutral' 
		/>
	</div>
</div>
</template>

<script>
import TextInput from '@/components/common/TextInput'
import EnabledButton from '@/components/common/buttons/Enabled'
import Logo from './Logo'

export default {
	name: 'EditHeaderFooter',
	components: { TextInput, EnabledButton, Logo },
	props: ['headerLine1', 'headerLine2', 'headerLine3', 'footer', 'logo', 'getUploadUrl'],
	data: function() {
		return {
			uploading: false
		}
	},
	computed: {},
	methods: {}
}
</script>

<style lang='scss'>
.editHeaderFooter {
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-gap: $size-gutter * 2;

	.formContent {
		padding: 0;
	}

	.controlBar {
		grid-column: 1 / -1;
	}
}
</style>
