<template>
<div class='settingsIdletime formContent'>
	<div>
		<header>{{$t('views.settings.idletime.title')}}</header>
		<p>{{$t('views.settings.idletime.p1')}} <b>{{time}}</b></p>
		<div class='sliderWrapper'>
			<div class='left'>{{$t('views.settings.idletime.minTime')}}</div>
			<Slider 
				@change='onSlideChange'
				:tooltip='tooltip'
				:min='15' 
				:max='720' 
				:interval='15' 
				:initial='timeout' 
			/>
			<div class='right'>{{$t('views.settings.idletime.maxTime')}}</div>
		</div>
		<SaveButton class='saveButton' @click='save' />
		<transition name='fade'><Loading v-show='savingIdletime' /></transition>
		<br />
	</div>
	<div v-if='!$store.state.profile.user.orgId || $store.state.profile.user.manager'>
		<header>{{$t('views.settings.secondaryAuth.title')}}</header>
		<p>{{$t('views.settings.secondaryAuth.p1')}}</p>
		<div class='authRow'>
			<div 
				v-tap='onClickAuth'
				class='authButton neutral iconTextButton' 
				:class='{ success: secondaryAuth, off: !secondaryAuth }'
			>
				<svg class='smallIcon'>
					<use xlink:href='#lock' />
				</svg>
				<span class='label'>{{secondaryAuth ? $t('elements.buttons.turnOff') : $t('elements.buttons.turnOn')}}</span>
			</div>
			<div>{{ secondaryAuth ? $t('views.settings.secondaryAuth.on') : $t('views.settings.secondaryAuth.off') }}</div>
		</div>
		<transition name='fade'><Loading v-show='savingAuth' /></transition>
	</div>
</div>
</template>

<script>
import SaveButton from '@/components/common/buttons/Save'
import Loading from '@/components/common/Loading'
import Slider from '@/components/common/Slider'

export default {
	name: 'SettingsIdletime',
	components: { SaveButton, Loading, Slider },
	data() {
		return {
			timeout: this.$store.state.profile.user.timeout,
			secondaryAuth: this.$store.state.profile.user.secondaryAuth,
			hours: null,
			minutes: null,
			tooltip: null,
			savingIdletime: false,
			savingAuth: false
		}
	},
	computed: {
		time() {
			let time = ''
			if (this.hours) {
				time += this.hours + ' '
				if (this.hours === 1) time += `${this.$t('elements.parts.hour')} `
				else time += `${this.$t('elements.parts.hours')} `
				if (this.minutes) time += `${this.$t('elements.parts.and')} `
			}
			if (this.minutes) {
				time += this.minutes + ` ${this.$t('elements.parts.minutes')}`
			}
			return time
		}
	},
	methods: {
		onSlideChange(value = this.timeout) {
			this.hours = Math.floor(value / 60)
			this.minutes = value % 60
			this.timeout = (this.hours * 60) + this.minutes
			this.tooltip = ('0' + this.hours).slice(-2) +':'+ ('0' + this.minutes).slice(-2)
		},
		async onClickAuth() {
			this.savingAuth = true
			const [err] = await this.$store.dispatch('profile/save', { secondaryAuth: !this.secondaryAuth })
			this.savingAuth = false
			if (!err) {
				this.$store.dispatch('flash/showAction', 'saved')
				this.secondaryAuth = !this.secondaryAuth
			}
		},
		async save() {
			this.savingIdletime = true
			const [err] = await this.$store.dispatch('profile/save', { timeout: this.timeout })
			this.savingIdletime = false
			if (!err) {
				this.$store.dispatch('flash/showAction', 'saved')
			}
		}
	},
	mounted() {
		this.onSlideChange()
	}
}
</script>

<style lang='scss'>
.settingsIdletime {
	padding-left: $size-gutter * 4;
	background: $color-white;

	> div {
		position: relative;
		max-width: 500px;
	}

	.sliderWrapper {
		padding-top: $size-gutter * 2;
		display: flex;
		align-items: center;
		.left, .right {
			flex: 0; white-space: nowrap;	
			font-size: $size-font-standard;
		}
		.left { padding-right: $size-gutter * 2; }
		.slider { flex: 1; }
		.right { padding-left: $size-gutter * 2; }
	}

	.saveButton {
		margin-top: $size-gutter * 5;
		width: 250px;
		border: 1px solid $color-neutral-shadow;
	}

	.authRow {
		display: flex;
		align-items: center;
		font-size: $size-font-standard;

		>div:last-child { margin-left: $size-gutter * 2; }
	}

	.authButton {
		width: 250px;
		&.off {
			border: 1px solid $color-neutral-shadow;			
		}		
	}
}
</style>
