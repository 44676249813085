<template>
<div class='settingsHeaders'>
	<Scroll>
		<div class='contentWrapper formContent'>
			<header>Client Portal Outgoing Link</header>
			<p>Add a custom link to your clients' Online Portal.  For example, a link to your website, your blog, your scheduling system, etc.</p>
			<div class="linkWrapper">
				<TextInput v-model='portalLink.title' placeholder='Link Title...' maxlength="32" :class='{ err: portalLink.errTitle }' @input="portalLink.errTitle=false" />
				<TextInput v-model='portalLink.url' placeholder='Link URL...' :class='{ err: portalLink.errUrl }' @input="portalLink.errUrl=false" />
			</div>

			<a 
				class='iconTextButton portalLink tagSuccess'
				:href='portalLink.url'
				target='_blank'
				v-if="portalLink.id"
			>
				<svg class='smallIcon'>
					<use xlink:href='#link' />
				</svg>
				<span class='label'>{{portalLink.title}}: {{portalLink.url}}</span>
			</a>

			<div class="controlBar">
				<div 
					class='saveLink iconTextButton neutral'
					@click='saveLink' 
				>
					<svg class='smallIcon'>
						<use xlink:href='#save' />
					</svg>
					<span class='label'>Save Link</span>
				</div>
				<div 
					class='iconTextButton neutral'
					v-if="portalLink.id"
					@click='clearLink'
				>
					<svg class='smallIcon'>
						<use xlink:href='#cancel' />
					</svg>
					<span class='label'>Remove Link</span>
				</div>
			</div>
			
			<header>{{$t('views.settings.headerFooters.title')}}</header>
			<p>{{$t('views.settings.headerFooters.p1')}}</p>

			<HeaderFooterList />
		</div>
	</Scroll>
	<transition name='fade'><Loading v-show='working' /></transition>
</div>
</template>

<script>
import { smallModalMixin } from '@/components/common/mixins/modal'
import AlertModal from '@/components/common/modals/Alert'
import Scroll from '@/components/common/Scroll'
import HeaderFooterList from '@/components/clinician/settings/headerFooters/List'

import { generalApi } from '@/services/api/modules/clinician/general'
import TextInput from '@/components/common/TextInput'
import SaveButton from '@/components/common/buttons/Save'
import Loading from '@/components/common/Loading'

export default {
	name: 'SettingsIdletime',
	mixins: [smallModalMixin],	
	data() { return {
		portalLink: {
			title: "",
			url: "",
			id: null,
			errTitle: false,
			errUrl: false
		},
		working: false
	}},
	components: { Scroll, HeaderFooterList, TextInput, SaveButton, Loading },
	methods: {
		async saveLink() {
			if (this.validateLink()) {
				const payload = { title: this.portalLink.title, url: this.portalLink.url }
				try {
					this.working = true
					if (this.portalLink.id) {
						const [err, result] = await generalApi.touch('updatePortalLink', payload)
						if (!err) {
							Object.assign(this.portalLink, result) 
							this.$store.dispatch('flash/showAction', 'saved')
						}
					} else {
						const [err, result] = await generalApi.touch('createPortalLink', payload)
						if (!err) {
							Object.assign(this.portalLink, result) 
							this.$store.dispatch('flash/showAction', 'saved')
						}
					}
				} finally {
					this.working = false
				}
			}
		},
		async clearLink() {
			try {
				this.working = true
				const [err] = await generalApi.touch('destroyPortalLink')
				if (!err) {
					this.portalLink = {
						title: "",
						url: "",
						id: null,
						errTitle: false,
						errUrl: false
					}
					this.$store.dispatch('flash/showAction', 'Link Removed')
				}
			} finally {
				this.working = false
			}
		},
		validateLink() {
			if (!this.portalLink.title) this.portalLink.errTitle = true
			try {
				new URL(this.portalLink.url)
			} catch (err) {
				this.portalLink.errUrl = true
			}
			return !this.portalLink.errTitle && !this.portalLink.errUrl
		}
	},
	mounted() {

		generalApi.touch('fetchPortalLink')
			.then(([err, result])=> {
				if (!err) {
					Object.assign(this.portalLink, result)
				}
			})
			.catch(()=>{})


		// wowza...
		this.$store.dispatch('headerFooters/setSelected', null)
			.then(() => this.$store.dispatch('headerFooters/fetch'))
			.then(() => {
				if (this.$store.state.profile.user.headerId) return this.$store.dispatch('headerFooters/setSelected', this.$store.state.profile.user.headerId)
			})
			.then(() => {
				this.unsubscribeStore = this.$store.subscribe(({ type, payload }) => {
					const key = payload
					if (type === 'headerFooters/setSelected') {
						if (key === null) {
							if (this.$store.state.profile.user.headerId) this.$store.dispatch('profile/save', { headerId: null })
						} else {
							if (this.$store.state.profile.user.headerFooterId !== key) {
								this.$store.dispatch('profile/save', { headerId: key })
								this.showSmallModal(AlertModal, {
									title: this.$t('views.clientPortalHeaderSet.title'),
									message: this.$t('views.clientPortalHeaderSet.p1')
								})		
							}
						}
					}
				})
			})
			.catch(err=>{})



	},
	beforeDestroy() {
		this.unsubscribeStore()
	}
}
</script>

<style lang='scss'>
.settingsHeaders {
	position: relative;
	background: $color-white;
	
	.linkWrapper {
		border: 1px solid $color-neutral-shadow;
		display: grid;
		grid-gap: 1px;
		background: $color-neutral-shadow;
		width: 75%;
	}

	.controlBar {
		border: 1px solid $color-neutral-shadow;
		width: 75%;
		margin: $size-gutter * 2 0;
	}

	.portalLink {
		width: 75%;
		svg { color: $color-black; }
	}

	.textInput.err, .passwordInput.err {
		color: $color-alert;
		&::placeholder { color: $color-alert !important; }
	}

	.contentWrapper {
		padding: 0 $size-gutter * 4;
		max-width: 700px;
	}

	.headerFootersWrapper {
		padding: $size-gutter * 2;
		border: 1px solid $color-neutral-accent;
	}
}
</style>
