<template>
<div class='slider'>
	<vue-slider
		v-model='value'
		@change='onChange'
		:data='data'
		:marks='marks'
		:min='min'
		:max='max'
		:interval='interval'
	>
		<template v-slot:label="{ label }">
			<div v-if='translate' :class="['vue-slider-mark-label']">{{$t('elements.slides.' + label)}}</div>
			<div v-else :class="['vue-slider-mark-label']">{{ label }}</div>
		</template>
		<template v-slot:tooltip="{ value, focus }">
			<div :class="['vue-slider-dot-tooltip-inner', 'vue-slider-dot-tooltip-inner-top', { focus }]"><span class='vue-slider-dot-tooltip-text'>
				<template v-if='tooltip'>{{ tooltip }}</template>
				<template v-else-if='translate'>{{$t('elements.slides.' + value)}}</template>
				<template v-else>{{ value }}</template>
			</span></div>
		</template>
	</vue-slider>
</div>
</template>

<script>
import VueSlider from 'vue-slider-component'

export default {
	name: 'Slider',
	components: { VueSlider },
	props: ['initial', 'values', 'min', 'max', 'interval', 'marks', 'translate', 'tooltip'],
	data() {
		return {
			value: this.initial,
			data: this.values
		}
	},
	computed: {},
	methods: {
		onChange(value) { this.$emit('change', value) }
	},
	mounted() {
		//console.log(this.initial)
	}
}
</script>
<style lang='scss'>
.slider {
	$themeColor: $color-primary-accent;
	$bgColor: $color-neutral-shadow !default;
	$dotShadow: 0 1px 2px 1px rgba(0, 0, 0, 0.15) !default;
	$dotShadowFocus: 0px 0px 1px 2px rgba($themeColor, 0.36) !default;
	$tooltipBorderRadius: 2px !default;
	$tooltipPadding: $size-gutter / 2 $size-gutter !default;
	$tooltipFontSize: $size-font-standard !default;
	$labelFontSize: $size-font-small !default;

	@import '~vue-slider-component/lib/theme/antd.scss';
}
</style>