<template>
<SmallModal class='closeModal' @close='$emit("close")'>
	<template v-slot:title>{{$t('views.closeAccount.title')}}</template>
	<template v-slot:message>
		<p>{{$t('views.closeAccount.p1')}}</p>
		<p>{{$t('views.closeAccount.p2')}}</p>
		<p>{{$t('views.closeAccount.p3')}}</p>
		<p>{{$t('views.closeAccount.p4')}}</p>
		<div class='inputWrapper'>
			<TextInput 
				v-model='textInput' 
				:placeholder='$t("views.closeAccount.phClose")' 
			/>
		</div>
	</template>
	<template v-slot:buttons>
		<EnabledButton 
			@click='showCloseReasonModal' 
			:disabled='textInput !== "CLOSE"' 
			:svg='"#closeAccount"' 
			:label='$t("views.closeAccount.title")' 
			class='primary' 
		/>
	</template>
</SmallModal>
</template>

<script>
import { smallModalMixin } from '@/components/common/mixins/modal'
import SmallModal from '@/components/common/modals/SmallModal'
import TextInput from '@/components/common/TextInput'
import EnabledButton from '@/components/common/buttons/Enabled'
import CloseReasonModal from './CloseReasonModal'

export default {
	mixins: [smallModalMixin],
	components: { SmallModal, TextInput, EnabledButton },
	data: () => ({ textInput: '' }),
	methods: {
		showCloseReasonModal() {
			this.$emit('close')
			this.showSmallModal(CloseReasonModal)
		}
	}
}
</script>

<style lang='scss'>
.closeModal {
	.inputWrapper {
		padding: $size-gutter * 2 0;
		.textInput {
			border: 1px solid $color-neutral-accent;
		}
	}
}
</style>