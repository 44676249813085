<template>
<div class='headerFooterRow' :class='{ selected }'>
	<div v-if='$store.state.headerFooters.editing === headerFooter.key' class='editing'>
		<EditHeaderFooter 
			:headerLine1='headerFooter.headerLine1'
			:headerLine2='headerFooter.headerLine2'
			:headerLine3='headerFooter.headerLine3'
			:footer='headerFooter.footer'
			:logo='headerFooter.logo'
			:getUploadUrl='getUploadUrl'
			@setHeaderLine1='onSetHeaderLine1'
			@setHeaderLine2='onSetHeaderLine2'
			@setHeaderLine3='onSetHeaderLine3'
			@setFooter='onSetFooter'
			@setLogo='onSetLogo'
			@unsetLogo='onUnsetLogo'
			@stopEdit='onStopEdit'
		/>
	</div>
	<div v-else class='summary'>
		<div>
			<CheckButton 
				:checked='selected' 
				@click='$store.dispatch("headerFooters/toggleSelected", headerFooter.key)' 
			/>
		</div>
		<div class='headerText'><div>
			<span v-if='headerFooter.headerLine1'>{{headerFooter.headerLine1}}</span>
			<span v-if='headerFooter.headerLine2'>{{headerFooter.headerLine2}}</span>
			<span v-if='headerFooter.headerLine3'>{{headerFooter.headerLine3}}</span>
		</div></div>
		<div v-if='headerFooter.logo' class='headerLogo'><div>
			<LazyImage :src='headerFooter.logo' :contain='true' />
		</div></div>
		<div v-else />
		<div v-if='headerFooter.type === "userHeaderFooter"'>
			<div class='editButton iconTextButton neutral' @click='$store.dispatch("headerFooters/setEditing", headerFooter.key)'>
				<svg class='smallIcon'>
					<use xlink:href='#edit' />
				</svg>
				<span class='label'>{{$t("elements.buttons.edit")}}</span>
			</div>
			<div class='deleteButton iconTextButton neutral' @click='confirmDelete'>
				<svg class='smallIcon'>
					<use xlink:href='#delete' />
				</svg>
				<span class='label'>{{$t("elements.buttons.delete")}}</span>
			</div>
		</div>
		<div v-else class='tag success'>{{$store.state.profile.teams[headerFooter.teamId].title}}</div>
	</div>

</div>
</template>

<script>
import { smallModalMixin } from '@/components/common/mixins/modal'
import { headerFootersApi } from '@/services/api/modules/clinician/headerFooters'
import { resourceDimensions } from '@/configuration/settings'
import ConfirmDeleteModal from '@/components/common/modals/ConfirmDelete'
import CheckButton from '@/components/common/buttons/Check'
import LazyImage from '@/components/common/LazyImage'
import EditHeaderFooter from './Edit'

export default {
	name: 'HeaderFooterRow',
	mixins: [smallModalMixin],
	components: { CheckButton, LazyImage, EditHeaderFooter },
	props: ['headerFooter'],
	computed: {
		selected() { return this.$store.state.headerFooters.selected === this.headerFooter.key },
	},
	methods: {
		async getUploadUrl() {
			const payload = { 
				id: this.headerFooter.key, 
				resourceWidth: this.$store.state.main.hiRes ? resourceDimensions.small * 2 : resourceDimensions.small 
			}
			return await headerFootersApi.touch('uploadLogo', payload)
		},
		onSetHeaderLine1(value) {
			this.$store.dispatch('headerFooters/setHeaderLine1', {
				key: this.headerFooter.key,
				value
			})
		},
		onSetHeaderLine2(value) {
			this.$store.dispatch('headerFooters/setHeaderLine2', {
				key: this.headerFooter.key,
				value
			})
		},
		onSetHeaderLine3(value) {
			this.$store.dispatch('headerFooters/setHeaderLine3', {
				key: this.headerFooter.key,
				value
			})
		},
		onSetFooter(value) {
			this.$store.dispatch('headerFooters/setFooter', {
				key: this.headerFooter.key,
				value
			})
		},
		onSetLogo(value) {
			this.$store.dispatch('headerFooters/setLogo', { 
				key: this.headerFooter.key, 
				value
			})
		},
		onUnsetLogo() {
			this.$store.dispatch("headerFooters/unsetLogo", this.headerFooter.key)
		},
		onStopEdit() {
			if (this.headerFooter.fresh) 
				this.$store.dispatch('headerFooters/update', this.headerFooter.key)
					.then(() => {
						this.$store.dispatch('headerFooters/setEditing', null)
						if (!this.$store.state.headerFooters.selected) this.$store.dispatch("headerFooters/toggleSelected", this.headerFooter.key)
					})
			else this.$store.dispatch('headerFooters/setEditing', null)
		},
		confirmDelete() {
			const fn = () => this.$store.dispatch('headerFooters/destroy', this.headerFooter.key)
			this.showSmallModal(ConfirmDeleteModal, { items: this.$t('views.headerFooter.lbl'), fn })
		}
	}
}
</script>

<style lang='scss'>
.headerFooterRow {
	position: relative;
	background: $color-white;
	margin-bottom: 1px;

	&.selected:before {
		@include absTopLeft;
		content: '';
		width: 100%;
		height: 100%;
		z-index: 1;
		border: 2px solid $color-focus;
		pointer-events: none;
	}

	&.selected:not(.editing):before {
		background: rgba($color-focus, .2);	
	}

	.summary {
		position: relative;
		z-index: 2;
		display: grid;
		grid-template-columns: auto auto 1fr auto;
		min-height: 46px;
		padding-top: $size-gutter * 2;
		padding-bottom: $size-gutter * 2;

		> div {
			display: flex;
			align-items: center;
		}

		.checkButton { 
			padding-left: $size-gutter * 2; 
		}

		.headerText {
			padding: 0 $size-gutter * 2; 
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
			flex-wrap: wrap;
			max-width: 100%;
			overflow: hidden;

			> div { min-width: 100%; }

			span { display: block; flex: 0 0 100%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
		}

		.headerLogo {
			border-left: 1px solid $color-neutral-accent;

			> div { 
				margin-left: $size-gutter * 2;
				width: 120px;
				height: 46px;
				img { object-position: center left; }
			}
		}
		
		.editButton, .deleteButton {
			border: 1px solid $color-neutral-shadow;
		}
		.deleteButton {
			border-left: none;
			margin-right: $size-gutter * 2;
		}
	}

	.tag { margin-right: $size-gutter * 2;}

	.editing {
		position: relative;
		z-index: 2;
		padding: $size-gutter * 4;
		background: $color-white;
	}

}
</style>
